import {
  Link,
  Button,
  Container,
  Typography,
  Stack,
  Card,
  Box,
  Divider,
  CircularProgress,
} from "@mui/material";
import useSettings from "../../hooks/useSettings";
import Page from "../../components/Page";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import { OrderCompleteIllustration, SeoIllustration } from "src/assets";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useEffect, useState } from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import HouseIcon from "@mui/icons-material/House";
import SwitchButton from "src/components/customized/switchButton/SwitchButton";
import getCatalogueSetup from "src/services/whatsApp/catalogue/getCatalogueSetup";
import CatalogueListDailog from "src/sections/@dashboard/whatsapp/catalogue/CatalogueListDailog";
import DisconnectCatalogue from "src/sections/@dashboard/whatsapp/catalogue/DisconnectCatalogue";
import EnableDisableCatalogueCart from "src/services/whatsApp/catalogue/EnableDisableCatalogueCart";
import { useSnackbar } from "notistack";
import EnableDisableCatalogueVisibility from "src/services/whatsApp/catalogue/EnableDisableCatalogueVisibility";
export default function Catalogue() {
  const { themeStretch } = useSettings();

  // Allow duplicates switch button state
  const [loading, setLoading] = useState({
    initialLoading: false,
    toggleCart: false,
    toggleVisibility: false,
  });
  const [cartEnabled, setCartEnabled] = useState(false);
  const [catalogueVisibility, setCatalogueVisibility] = useState(false);
  const [chooseCatalogue, setChooseCatalogue] = useState(false);
  const [disconnectCatalogue, setDisconnectCatalogue] = useState(false);
  const [catalogueSetupDetails, setCatalogueSetupDetails] = useState(null);

  const { login_url, catalog_account, connected_catalog } =
    catalogueSetupDetails || {};

  const { commerce_setup } = connected_catalog || {};
  const { is_cart_enabled, is_catalog_visible } = commerce_setup || {};

  const { enqueueSnackbar } = useSnackbar();

  const handleEnableCart = async () => {
    try {
      setLoading((prev) => ({ ...prev, toggleCart: true }));
      setCartEnabled(!cartEnabled);
      const response = await EnableDisableCatalogueCart(
        is_cart_enabled ? "0" : "1"
      );
      setCatalogueSetupDetails(response);
      enqueueSnackbar("Catalogue Cart Stutus Updated Successfully!");
    } catch (error) {
      console.log(error);
      typeof error === "string" && enqueueSnackbar(error, { variant: "error" });
      if (error?.error?.message) {
        enqueueSnackbar(error.error.message, {
          variant: "error",
          autoHideDuration: 10000,
        });
      }
      error?.errors &&
        Object.keys(error?.errors).forEach((key) =>
          enqueueSnackbar(error?.errors[key], {
            variant: "error",
            autoHideDuration: 10000,
          })
        );
    } finally {
      setLoading((prev) => ({ ...prev, toggleCart: false }));
    }
  };

  const handleVisibilityCart = async () => {
    try {
      setLoading((prev) => ({ ...prev, toggleVisibility: true }));
      setCatalogueVisibility(!catalogueVisibility);
      const response = await EnableDisableCatalogueVisibility(
        is_catalog_visible ? "0" : "1"
      );
      setCatalogueSetupDetails(response);
      enqueueSnackbar("Catalogue Visibility Updated Successfully!");
    } catch (error) {
      console.log(error);
      typeof error === "string" && enqueueSnackbar(error, { variant: "error" });
      if (error?.error?.message) {
        enqueueSnackbar(error.error.message, {
          variant: "error",
          autoHideDuration: 10000,
        });
      }
      error?.errors &&
        Object.keys(error?.errors).forEach((key) =>
          enqueueSnackbar(error?.errors[key], {
            variant: "error",
            autoHideDuration: 10000,
          })
        );
    } finally {
      setLoading((prev) => ({ ...prev, toggleVisibility: false }));
    }
  };
  const handleChooseCatalogue = () => {
    setChooseCatalogue(true);
  };

  const handleclose = () => {
    setChooseCatalogue(false);
  };

  const handleDisconnectCatalogue = () => {
    setDisconnectCatalogue(true);
  };

  const handlecloseCatalogue = () => {
    setDisconnectCatalogue(false);
  };

  useEffect(() => {
    const getCatalogueSetupData = async () => {
      try {
        setLoading((prev) => ({ ...prev, initialLoading: true }));
        const response = await getCatalogueSetup();
        setCatalogueSetupDetails(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading((prev) => ({ ...prev, initialLoading: false }));
      }
    };
    getCatalogueSetupData();
  }, []);

  useEffect(() => {
    if (is_cart_enabled) {
      setCartEnabled(is_cart_enabled);
    }
  }, [is_cart_enabled]);

  useEffect(() => {
    if (is_catalog_visible) {
      setCatalogueVisibility(is_catalog_visible);
    }
  }, [is_catalog_visible]);

  return (
    <Page title="WhatsApp: Catalogue">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading="WhatsApp Catalogue"
          links={[{ name: "WhatsApp" }, { name: "Catalogue" }]}
          action={
            <Link href={login_url}>
              <Button
                variant="contained"
                startIcon={<FacebookIcon />}
                color="secondary"
              >
                Connect Account
              </Button>
            </Link>
          }
        />

        {loading?.initialLoading ? (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            height="60vh"
          >
            <CircularProgress size={30} />
          </Stack>
        ) : (
          <>
            {/* connect account start */}
            {catalog_account?.length === 0 &&
              connected_catalog?.length === 0 && (
                <Stack
                  sx={{ maxWidth: "100%", mt: 10 }}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <SeoIllustration sx={{ width: "25%", mb: 2 }} />

                  <Typography variant="h6">
                    No Facebook Account Connected
                  </Typography>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Plase Connect One
                  </Typography>
                  <Link href={login_url}>
                    <Button
                      size="small"
                      variant="contained"
                      startIcon={<FacebookIcon />}
                      color="secondary"
                    >
                      Connect Account
                    </Button>
                  </Link>
                </Stack>
              )}

            {/* connect account end */}

            {/* connect catalogue start */}
            {catalog_account?.length !== 0 &&
              connected_catalog?.commerce_setup?.length === 0 &&
              connected_catalog?.id === "" && (
                <Stack
                  sx={{ maxWidth: "100%", mt: 10 }}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <OrderCompleteIllustration sx={{ width: "25%", mb: 2 }} />

                  <Typography variant="h6">Connect a Catalog</Typography>
                  <Typography variant="body2">
                    Showcase your products and services to
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    customers on WhatsApp.
                  </Typography>

                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={handleChooseCatalogue}
                  >
                    Choose a Catalog
                  </Button>
                </Stack>
              )}

            {/* connect catalogue end */}

            {/* catalogue list dialog start */}
            {catalog_account?.length !== 0 &&
              connected_catalog?.commerce_setup?.length !== 0 &&
              connected_catalog?.id !== "" && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ mt: 10 }}
                >
                  <Card sx={{ maxWidth: "md" }}>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      sx={{ p: 1, ml: 2 }}
                    >
                      <ShoppingCartIcon
                        sx={{
                          borderRadius: "50%",
                          p: 1,
                          width: 40,
                          height: 40,
                          backgroundColor: "#F0F0F0",
                        }}
                      />
                      <Typography sx={{ fontWeight: 600 }}>
                        {catalog_account &&
                          catalog_account?.catalogs?.filter(
                            (item) => item.id === connected_catalog?.id
                          )[0]?.name}
                      </Typography>
                    </Stack>
                    <Divider />

                    <Box sx={{ p: 3 }}>
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent={"space-between"}
                        sx={{ mb: 2 }}
                      >
                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          sx={{ maxWidth: "80%" }}
                        >
                          <Box
                            sx={{
                              borderRadius: "50%",
                              p: 1,
                              width: 40,
                              height: 40,
                              backgroundColor: "#F0F0F0",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <HouseIcon />
                          </Box>
                          <Box>
                            <Typography sx={{ fontWeight: 800 }}>
                              Show catalog icon in chat header{" "}
                            </Typography>
                            <Typography variant="body2" textAlign="justify">
                              Turning on catalog will add an icon to your chat
                              header and in your business profile for your
                              customers to easily access your catalog of
                              products.
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack>
                          {loading?.toggleCart ? (
                            <Stack
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <CircularProgress size={15} />
                            </Stack>
                          ) : (
                            <SwitchButton
                              status={cartEnabled}
                              toggleStatus={handleEnableCart}
                              enabledText={"On"}
                              disabledText={"Off"}
                            />
                          )}
                        </Stack>
                      </Stack>

                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent={"space-between"}
                        sx={{ mb: 3 }}
                      >
                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          sx={{ maxWidth: "80%" }}
                        >
                          <Box
                            sx={{
                              borderRadius: "50%",
                              p: 1,
                              width: 40,
                              height: 40,
                              backgroundColor: "#F0F0F0",
                            }}
                          >
                            <ShoppingCartIcon />
                          </Box>
                          <Box>
                            <Typography sx={{ fontWeight: 800 }}>
                              Show "Add to cart" button on product pages and
                              chat{" "}
                            </Typography>
                            <Typography variant="body2" textAlign="justify">
                              The “Add to cart” button will be available from
                              product pages and lets customers add items to a
                              cart when shopping in your catalog. The cart icon
                              will also be added to your chats and in product
                              list pages. Turning this off won’t affect carts
                              you’ve already received.
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack>
                          {loading?.toggleVisibility ? (
                            <Stack
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <CircularProgress size={15} />
                            </Stack>
                          ) : (
                            <SwitchButton
                              status={catalogueVisibility}
                              toggleStatus={handleVisibilityCart}
                              enabledText={"On"}
                              disabledText={"Off"}
                            />
                          )}
                        </Stack>
                      </Stack>

                      <Divider sx={{ mb: 2 }} />
                      <Button
                        variant="outlined"
                        onClick={handleDisconnectCatalogue}
                      >
                        Disconnect Catalogue
                      </Button>
                    </Box>
                  </Card>
                </Stack>
              )}
          </>
        )}
      </Container>
      <CatalogueListDailog
        open={chooseCatalogue}
        onClose={handleclose}
        data={catalogueSetupDetails}
        setData={setCatalogueSetupDetails}
      />
      <DisconnectCatalogue
        open={disconnectCatalogue}
        onClose={handlecloseCatalogue}
        data={catalogueSetupDetails}
        setData={setCatalogueSetupDetails}
      />
    </Page>
  );
}
